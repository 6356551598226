import { Injectable } from '@angular/core';
import { ImageParamsWrapper } from '@usheru-hff/shared/domain';
import { ChannelService } from '@usheru-hff/shared/utils-channel';
import { Subject } from 'rxjs';

const PORTRAIT_DEFAULT_PLACEHOLDER = 'https://cdnstatic.usheru.com/misc/portrait-default.jpg';
const LANDSCAPE_DEFAULT_PLACEHOLDER = 'https://cdnstatic.usheru.com/misc/landscape-default.jpg';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private readonly bucketAssets: string;
  private readonly channelTilePhoto: string;
  private readonly channelPortraitPhoto: string;
  private readonly domain: string;
  public readonly portraitPlaceholder: string;
  public readonly landscapePlaceholder: string;

  private _imagesLoading = new Subject<number>();
  private images: Map<HTMLElement, boolean> = new Map();
  private imagesLoading = 0;
  imagesLoading$ = this._imagesLoading.asObservable();

  constructor(private channelService: ChannelService) {
    this.channelTilePhoto = channelService.channel.tilePhotoUrl;
    this.channelPortraitPhoto = channelService.channel.portraitPhoto ? channelService.channel.portraitPhotoUrl : null;
    this.portraitPlaceholder = channelService.channel.portraitPhoto ? channelService.channel.portraitPhotoUrl : PORTRAIT_DEFAULT_PLACEHOLDER;
    this.landscapePlaceholder = channelService.channel.tilePhoto ? channelService.channel.tilePhotoUrl : LANDSCAPE_DEFAULT_PLACEHOLDER;
    this.domain = channelService.channel.domain;
  }

  /**
   * Method that will compute an url and some params (ImageParamsWrapper) and will return the encoded url ready to use as an image
   * @param url URL of the image that is present in the usheru static bucket
   * @param params it is and ImageParamsWrapper
   * @returns the encoded url that uses the aws image handler
   */
  computeUrl(url: string, params?: ImageParamsWrapper): string {
    // In case there is not url to work with then
    if (!url) {
      // try to use a placeholder
      if (params.placeholder) {
        url = params.placeholder;
      } else {
        if (params.type == 'portrait') {
          url = this.channelPortraitPhoto;
        } else {
          url = this.channelTilePhoto;
        }
      }
    }
    // If there is not even channel default placeholders return nothing
    if (!url) {
      return '';
    }
    const relPath = this.getRelPathFromUrl(url);
    const encodedUrl = this.getEncodedUrl(relPath, params);

    return encodedUrl;
  }

  /**
   * it will get a relative path and params such as height or fit and will create the
   * final url querying the cloudFront implementation  in AWS
   */
  private getEncodedUrl(relativePath: string, params?: ImageParamsWrapper): string {
    // relativePath: img/movies/myimage.jpg

    const cloudFrontUrl = 'https://img.usheru.com/';

    const defaultParams = {
      bucket: 'static.usheru.com',
      fit: 'cover'
    };

    // building up the request for the Image Handling API
    const bucket = defaultParams.bucket;
    const key = relativePath;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const edits: any = {};
    edits.resize = {};
    edits.resize.fit = params?.fit || defaultParams.fit;
    if (params?.width) edits.resize.width = params.width;
    if (params?.height) edits.resize.height = params.height;
    if (params?.fillColor) edits.resize.background = params.fillColor;

    const request = {
      bucket: bucket,
      key: key,
      edits: edits
    };

    const strRequest = JSON.stringify(request);
    const encRequest = btoa(strRequest);
    return `${cloudFrontUrl}${encRequest}`;
  }

  /**
   * Returns the relative path to image from an url
   * @param url Url in the format http or https
   * @returns relative path in for the image e.g.
   * https://cdnstatic.usheru.com/img/movies/away-1_5b3573db-bc2e-475c-b825-63a5526e6608.jpg ->
   * img/movies/away-1_5b3573db-bc2e-475c-b825-63a5526e6608.jpg
   */
  getRelPathFromUrl(url: string): string {
    // find position of img (every image will be in this forlder in aws)
    const imgIndex = url.indexOf('/img');
    return url.slice(imgIndex + 1);
  }

  imageLoading(img: HTMLElement) {
    if (!this.images.has(img) || this.images.get(img)) {
      this.images.set(img, false);
      this.imagesLoading++;
      this._imagesLoading.next(this.imagesLoading);
    }
  }

  imageLoadedOrError(img: HTMLElement) {
    if (this.images.has(img) && !this.images.get(img)) {
      this.images.set(img, true);
      this.imagesLoading--;
      this._imagesLoading.next(this.imagesLoading);
    }
  }
}
