import { ImageLoaderConfig } from '@angular/common';

import { Environment } from '@environment';
import { Channel, ImageParamsWrapper } from '@usheru-hff/shared/domain';

export const myCustomLoader = (config: ImageLoaderConfig, channel: Channel, enviroment: Environment) => {
  const cloudFrontUrl = 'https://img.usheru.com/';

  const defaultParams = {
    bucket: 'static.usheru.com',
    fit: 'cover'
  };

  // Uses the `defaultImagePlaceholder` environment property function to get the channel property holding the image url.
  const defaultPlaceholder = enviroment.defaultImagePlaceholder ? enviroment.defaultImagePlaceholder(channel) : '';

  const url = config.src;
  // return 'https://img.usheru.com/eyJidWNrZXQiOiJzdGF0aWMudXNoZXJ1LmNvbSIsImtleSI6ImltZy9tb3ZpZXMvdzc4MF8yZjd0MVlhQWxacDY3dkFDMHBnZ1k3RmFFd2kuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvdmVyIn19fQ==';
  // If there is not even channel default placeholders return nothing
  if (!url) {
    return '';
  }
  const params: ImageParamsWrapper = {};
  params.fit = config.loaderParams?.fit;
  params.fillColor = config.loaderParams?.fillColor;

  if (config.loaderParams?.keepHeight) {
    params.height = config.loaderParams.keepHeight;
  } else {
    params.width = config.width;
  }

  if (!(typeof url === 'string')) {
    return defaultPlaceholder;
  }
  const imgIndex = url.indexOf('/img');
  const relPath = imgIndex >= 0 ? url.slice(imgIndex + 1) : null;

  // If the relative path is invalid, return the default placeholder
  if (!relPath) {
    return defaultPlaceholder;
  }

  //   get encoded url
  function utf8ToBase64(str) {
    const encoded = new TextEncoder().encode(str);
    return btoa(String.fromCharCode(...encoded));
  }

  // building up the request for the Image Handling API
  const bucket = defaultParams.bucket;
  const key = relPath;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const edits: any = {};
  edits.resize = {};
  edits.resize.fit = params?.fit || defaultParams.fit;
  if (params?.width) edits.resize.width = params.width;
  if (params?.height) edits.resize.height = params.height;
  if (params?.fillColor) edits.resize.background = params.fillColor;

  const request = {
    bucket: bucket,
    key: key,
    edits: edits
  };

  const strRequest = JSON.stringify(request);
  const encRequest = utf8ToBase64(strRequest);

  const encodedUrl = `${cloudFrontUrl}${encRequest}`;

  return encodedUrl;
};
